<template>
  <div class="previewLayout">
    <vb-back class="pdfBtn" @backChange="pdfChange">PDF导出</vb-back>
    <vb-back @backChange="backChange" class="back-Btn">返回操作</vb-back>
    <div id="previewLayBox">
      <div class="previewHeader">
        <h1 class="previewTitle">VB-MAPP评估报告表</h1>
        <ul class="preview-info">
          <li>
            <strong>孩子姓名：</strong>
            <span>{{childrenInfo.child_name}}</span>
          </li>
          <li>
            <strong>出生日期：</strong>
            <span>{{childrenInfo.birth}}</span>
          </li>
          <li>
            <strong>评估日期：</strong>
            <span>{{childrenInfo.assess_time}}</span>
          </li>
        </ul>
      </div>
      <div class="previewItem">
        <div class="previewItemHead">VB-MAPP里程碑计分表</div>
        <assessment-record :isEdit="false" :isInfo='false'></assessment-record>
      </div>
      <div class="previewItem">
        <div class="previewItemHead">VB-MAPP评估结果分析</div>
        <vb-radar-chart class="mainChart" :radarChartDate="chartDateInfo" :fontSizeFooter="16" :titleSize="22" :fontSize="14"></vb-radar-chart>
        <vb-line-charts class="mainChart" :lineChartDate="chartDateInfo" :fontSizeFooter="16" :titleSize="22" :fontSize="14"></vb-line-charts>
        <!-- <div class="caseInfo">
          <div>
            <strong>评估结论</strong>
          </div>
          <p>
            VB-MAPP里程碑评估包括提要求、命名、听者反应等16类学习和语言技能，根据正常孩子的发展进程，里程碑被划分为三个阶段（0~18个月、18~30个月和30~48个月）。
            <br />
            <span style="color:#FF8B3D">{{childrenInfo.child_name}}</span>
            在此次VB-MAPP里程碑评估中得{{childrenInfo.score}}（总分为170分）。（第一阶段0~45分、第二阶段46~105分、第三阶段106~170分）。报告对被评估的技能做单项分析，并为其制定相应的教学训练方案。
          </p>
        </div> -->
      </div>
      <div class="previewItem assessmentResult" v-if="oneTableResult.length">
        <div class="previewItemHead">孩子优势、不足与训练建议</div>
        <a-table
          bordered
          :columns="columnsResult"
          :data-source="oneTableResult"
          :pagination="false"
          :row-key="(record,index) =>index"
        >
          <a slot="name" slot-scope="text">{{ text }}</a>
        </a-table>
      </div>
      <div class="previewItem assessmentResult" v-if="twoTableResult.length">
        <div class="previewItemHead">孩子优势、不足与训练建议</div>
        <a-table
          bordered
          :columns="columnsResult"
          :data-source="twoTableResult"
          :pagination="false"
          :row-key="(record,index) =>index"
        >
          <a slot="name" slot-scope="text">{{ text }}</a>
        </a-table>
      </div>
      <div class="previewItem assessmentResult assessmentResult2" v-if="threeTableResult.length">
        <div class="previewItemHead">孩子优势、不足与训练建议</div>
        <a-table
          bordered
          :columns="columnsResult"
          :data-source="threeTableResult"
          :pagination="false"
          :row-key="(record,index) =>index"
        >
          <a slot="name" slot-scope="text">{{ text }}</a>
        </a-table>
      </div>
      <div class="previewItem previewPlan" v-for="(item,index) in tableData" :key="index">
        <div class="previewItemHead">VB-MAPP训练计划</div>
        <a-table
          bordered
          :columns="columnsPlan"
          :data-source="tableSource(item)"
          :pagination="false"
          :row-key="(record,index) =>index"
          :loading="searchBtnLoading"
          class="oneTable"
        >
          <a slot="name" slot-scope="text">{{ text }}</a>
          <template slot="planOperation" slot-scope="text, record">
            <div v-for="(item2,indey) in record.plan" :key="indey">
              <p
                v-for="(subItem,indez) in item2"
                :style="foSize(subItem, record.plan)"
                :key="indez"
              >{{subItem}}</p>
            </div>
          </template>
        </a-table>
      </div>
      <div class="previewItem proposal">
        <div class="previewItemHead">评估小结及排课建议</div>
        <div class="proposalBody">
          <h3 class="title" style="margin-top:33px">
            <span class="iconLine">评估小结</span>
          </h3>
          <div class="proposalItemInfo" style="margin-bottom:27px">
            <p>VB-MAPP里程碑评估包括提要求、命名、听者反应等16类学习和语言技能，根据正常孩子的发展进程，里程碑被划分为三个阶段（0~18个月、18~30个月和30~48个月）。</p>
            <p>
              <i class="colorName">{{childrenInfo.child_name}}</i>在此次VB-MAPP里程碑评估中得{{classInfo.score}}分（总分为170分）。目前相当于正常孩子{{classInfo.content}}的水平（第一阶段0~45分、第二阶段46~105分、第三阶段106~170分）。报告对被评估的技能做单项分享，并推荐相应的教学训练方案。
            </p>
          </div>
           <div class="proposalItemInfo" style="min-height:478px;margin-bottom:32px">
            <p v-html="formatTxt(classInfo.summary)"></p>
          </div>
        </div>
        <div class="proposalBody">
          <h3 class="title">
            <span class="iconLine">排课建议</span>
          </h3>
          <h2 class="proposalTitle"><i class="colorName">{{childrenInfo.child_name}}</i>能力目前属于星希望<i class="colorName">{{classInfo.class}}</i>班第<i class="colorName">{{classInfo.grade}}</i>级</h2>
          <div class="proposalItemInfo">
            <ul>
              <li>
                <strong>训练时长：</strong>
                <div v-if="classInfo.month">
                  <i class="colorName">{{classInfo.month}}</i>
                </div>
              </li>
              <li>
                <strong>训练时间：</strong>
                <div class="timeItem" v-if="classInfo.cycle">
                  <span v-for="(item,indx) in classInfo.cycle" :key="indx">{{item}}</span>
                </div>
              </li>
              <li>
                <strong>训练课程：</strong>
                <div class="teamClass" v-if="Object.values(classCourse).filter(v=>v*1>0).length!==0">
                    <template v-for="(value,key,index) in classCourse">
                      <strong :key="index" v-if="index!==0">+</strong>
                      <span :key="index+key"><i class="colorName">{{value}}</i>{{classInfoTxt[key]}}</span>
                    </template>
                  <span>/天</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="signItem">
          <strong>评估老师签字 :</strong>
          <span></span>
        </div>
      </div>
      <div class="previewFooter whole-node">
        <h2>
          <div class="companyImg">
            <img src="~/assets/images/preview/imag.png" crossorigin="Anonymous" />
          </div>
          <div class="company_info_item">
            <h3>
              <span>星希望介绍</span>
              <b>XING XI WANG INTRODUCTION</b>
            </h3>
            <div class="company_info">
              <p>星希望教育成立于2011年1月，是一家致力于为孤独症(自闭症)等特殊群体提供咨询、教育培训、康复服务的O2O教育型企业。独创中国首套具有自主知识产权的孤独症综合疗法MUST行为引导技术。</p>
              <p>星希望分别在北京、长沙、重庆、广州建设南北四大MUST康复基地，通过线上和线下培训，帮助自闭症群体和老师。线上业务通过互联网平台，为自闭症孩子家长、康复老师提供视频课程培训，在线督导，课程指导等服务;线下业务则提供自闭症孩子的测评、方案制定、康复训练、家长面授培训等。</p>
              <p>迄今为止，星希望已为8000余名孤独症谱系障碍的孩子提供康复训练，举办家长心理疏导300余场，技术培训500多场，干预5万多个孤独症家庭。希望通过我们的努力，让中国1300万孤独症家庭生活更从容。</p>
              <!-- <p>星希望教育始于2011年，践行“让孩子成长变得更容易”的企业使命，以自主创立的MUST行为引导技术为核心竞争力，并在此技术上开发了MUST系统评估、线下康复、在线培训、师资认证、MUST教具开发等业务板块。</p>
              <p>星希望自创办以来，始终秉承“激发动机，提升能力”的教育理念并以MUST行为引导技术为载体，不断尝试运用先进的康复技术提升教学质量，探索不同年龄、层次的孩子不同的康复需求，提供包括单训、合训、班级课、幼儿园融合、小学融合、就业指导等多层次服务，并设有线下康复、在线培训、双线教学等多种教学模式。</p>
              <p>当前，星希望凭借“互联网+康复教育“的模式与近十年的品牌战略价值优势，分别在北京和长沙建设和运营南、北两大MUST康复基地，形成了 “南北落点，以点带面，辐射全国”的产业战略布局，为全国不同省份的家庭提供高品质的康复服务。</p> -->
            </div>
          </div>
          <div class="company_adrees">
            <div class="company_adrees_item">
              <div>
                <p>
                  <strong>官网网址：</strong>https://www.xingxiwang.com/
                </p>
                <p>
                  <strong>WEB网址：</strong>https://web.xingxiwang.com/
                </p>
                <p>
                  <strong>热线电话：</strong>18519861379（周一至周日8:00-21:00）
                </p>
                <p>
                  <strong>公司地址：</strong>北京市昌平区星希望教育昌发展科教中心4层
                </p>
              </div>
              <div class="company_img">
                <img
                  style="margin-right:22px"
                  src="~/assets/images/preview/gzh.png"
                  crossorigin="*"
                />
                <img src="~/assets/images/preview/fwh.png" crossorigin="*" />
              </div>
            </div>
          </div>
        </h2>
      </div>
    </div>
  </div>
</template>
<script>
import vbBack from '@/components/common/vb-back'
import assessmentRecord from '@/views/evaluatingDetail/component/assessmentRecord.vue'
import vbLineCharts from '@/components/content/charts/lineChart'
import vbRadarChart from '@/components/content/charts/radarChart'
import { mapState } from 'vuex'
import htmlToPdf from '@/utils/htmlToPdf.js'
import { getplan, getClassideaList } from '@/api/record'
export default {
  name: 'previewLayout',
  components: {
    assessmentRecord,
    vbBack,
    vbLineCharts,
    vbRadarChart
  },
  data () {
    return {
      isSize: false,
      Index: this.$route.query.curIndex,
      recodeTxt: '',
      columnsResult: [{
        title: '领域',
        dataIndex: 'name',
        align: 'center',
        key: 'name',
        width: '125px'
      },
      {
        title: '阶段',
        dataIndex: 'stage_name',
        align: 'center',
        key: 'stage_name',
        width: '125px'
      },
      {
        title: '里程碑',
        dataIndex: 'plan_num',
        align: 'center',
        key: 'plan_num',
        width: '110px',
        customRender: text => {
          return text + 'M'
        }
      },
      {
        title: '得分',
        dataIndex: 'fraction',
        align: 'center',
        key: 'fraction',
        width: '100px'
      },
      {
        title: '总分',
        dataIndex: 'total_score',
        align: 'center',
        key: 'total_score',
        width: '100px'
      },
      {
        title: '孩子优势',
        dataIndex: 'merit',
        key: 'merit',
        width: '303px'
      },
      {
        title: '不足及训练建议',
        dataIndex: 'defect',
        key: 'defect',
        width: '303px'
      }
      ],
      columnsPlan: [{
        title: '训练领域',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        width: '140px'
      },
      {
        title: '训练目标',
        dataIndex: 'mil_name',
        key: 'mil_name',
        align: 'center',
        width: '140px'
      },
      {
        title: '训练计划详细内容',
        dataIndex: 'plan',
        key: 'plan',
        scopedSlots: {
          customRender: 'planOperation'
        }
      }
      ],
      searchBtnLoading: false,
      tableData: [],
      classInfo: {},
      classInfoTxt: { train: '节个训', muscle: '节口肌', function: '节职能', close: '节合训', team: '节集体', parent: '节家长训练' },
      classCourse: {}
    }
  },
  computed: {
    ...mapState(['childrenInfo', 'chartDateInfo', 'oneTableResult', 'twoTableResult', 'threeTableResult'])
  },

  mounted () {
    this.getPlanList()
    this.getClassInfo()
  },
  methods: {
    pdfChange () {
      // debugger
      htmlToPdf.downloadPDF(document.querySelector('#previewLayBox').children, 'VB-MAPP评估报告表')
    },
    backChange () {
      this.$router.push({ path: '/evaluatingDetail', query: { curIndex: this.$route.query.curIndex, mobile: this.$route.query.mobile } })
    },
    async getPlanList () {
      this.searchBtnLoading = true
      const res = await getplan(this.$route.query.mobile)
      if (res.code !== '1') {
        return this.$message.error(res.msg)
      }
      this.searchBtnLoading = false
      this.tableData = res.data.list
      // console.log(this.tableData)
    },
    tableSource (item) {
      return [item]
    },
    foSize (item, item2) {
      // console.log('item2', item2)
      const planLength = item2.flatMap(item => item)
      // console.log(planLength, 'planLength')
      if (planLength.length <= 14) {
        return { fontSize: 22 + 'px', lineHeight: 55 + 'px' }
      } else if (planLength.length <= 16) {
        return { fontSize: 21 + 'px', lineHeight: 54 + 'px' }
      } else if (planLength.length <= 19) {
        return { fontSize: 20 + 'px', lineHeight: 46 + 'px' }
      } else if (planLength.length <= 22) {
        return { fontSize: 19 + 'px', lineHeight: 36 + 'px' }
      } else {
        return { fontSize: 17 + 'px', lineHeight: 36 + 'px' }
      }
    },
    async getClassInfo () {
      const res = await getClassideaList(this.$route.query.mobile)
      if (res.code !== '1') {
        return this.$message.error(res.msg)
      }
      this.classInfo = {
        class: this.classTxt(res.data.class),
        grade: this.gradeTxt(res.data.grade),
        score: res.data.total_score,
        content: res.data.content,
        summary: res.data.summary,
        month: this.monthTxt(res.data.month),
        cycle: this.cycleTxt(res.data.cycle)

      }
      this.classCourse = {
        train: res.data.train,
        muscle: res.data.muscle,
        function: res.data.function,
        close: res.data.close,
        team: res.data.team,
        parent: res.data.parent
      }
      for (const key in this.classCourse) {
        if (Number(this.classCourse[key]) === 0) {
          delete this.classCourse[key]
        }
      }
    },
    classTxt (txt) {
      return ['', '语萌', '语进', '语达', '幼融', '学业'][txt]
    },
    gradeTxt (txt) {
      return ['', '一', '二', '三'][txt]
    },
    cycleTxt (arr) {
      if (arr) {
        return arr.map(v => {
          return ['', '周一', '周二', '周三', '周四', '周五', '周六', '周日'][v]
        })
      }
    },
    formatTxt (text) {
      if (text) {
        // eslint-disable-next-line no-control-regex
        return text.replace(new RegExp('\n', 'g'), '<br />')
      }
    },
    monthTxt (txt) {
      return ['', '1周', '1个月', '3个月', '6个月', '12个月'][txt]
    }
    // trainTxt (txt) {
    //   const obj = { 1: '课内1', 2: '课内2', 3: '课内3', 4: '课内4', 5: '课内5', 6: '课内6', 7: '课内7', 8: '课内8', 9: '课内9', 10: '加课10', 11: '加课11', 12: '加课12', 13: '加课13', 14: '加课14', 15: '加课15' }
    //   let arg = null
    //   for (const key in obj) {
    //     if (txt === key) {
    //       arg = obj[key]
    //     }
    //   }
    //   return arg
    // }
  }
}
</script>
<style lang="less" scoped>
.teamClass{
  >*{float: left;}
  >strong{
font-size:40px;color:#000;line-height: 23px;font-weight: 400; padding: 0 3px;
  }
}
.previewLayout {
  width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  .previewHeader {
    height: 1698px;
    width: 100%;
    background: url('../../assets/images/preview/bg.png');
    position: relative;
    overflow: hidden;
    .preview-info {
      width: 406px;
      padding-top: 207px;
      margin: 0 auto;
      > li {
        display: flex;
        font-size: 30px;
        margin-bottom: 50px;
        strong {
          width: 150px;
          font-weight: normal;
        }
        span {
          flex: 1;
          width: 100%;
          border-bottom: 1px solid #707070;
          text-align: center;
          font-size: 36px;
        }
      }
    }
    > h4 {
      font-size: 26px;
      line-height: 44px;
      position: absolute;
      bottom: 62px;
      text-align: center;
      width: 100%;
      margin: 0;
    }
  }
  .previewFooter {
    .companyImg {
      padding-left: 253px;
      background: #ff5f4c;
      height: 582px;
      width: 771px;
    }
    .company_info_item {
      padding: 136px 74px 0;

      > h3 {
        font-size: 26px;
        line-height: 44px;
        font-weight: 500;
        margin-bottom: 20px;
        > span {
          font-size: 46px;
          color: #ff693f;
          display: block;
        }
        > b {
          color: #a8a8a8;
          font-size: 22px;
          font-weight: 500;
        }
      }
      .company_info {
        margin-bottom: 55px;
        > p {
          text-indent: 2em;
          margin-bottom: 26px;
          font-size: 22px;
          line-height: 42px;
          color: #666;
        }
      }
    }
    .company_adrees {
      background: url('../../assets/images/preview/bg_footer.png') no-repeat;
      height: 340px;
      margin-bottom: 104px;
      .company_adrees_item {
        strong {
          font-weight: normal;
        }
        p {
          margin: 0;
        }
        width: 923px;
        margin: 0 auto;
        line-height: 42px;
        font-size: 20px;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
      }
      .company_img {
        img {
          width: 185px;
          height: 185px;
        }
      }
    }
  }
}
.recodeCompon {
  overflow: hidden;
}
.pdfBtn {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
}
.backBtn {
  margin: 0;
}
::v-deep .baseInfo {
  box-shadow: none !important;
  ul {
    width: 1012px;
  }
  li {
    font-size: 24px;
    line-height: 23px;
    padding-top: 39px;
  }
  ul li span:nth-child(1) {
    width: 142px;
  }
  ul li span:nth-child(1)::before {
    width: 29px;
    height: 29px;
    background: url('../../assets/images/preview/iconBig.png') no-repeat;
  }
}
::v-deep .assessmentPlan {
  .planTable .ant-table {
    font-size: 20px;
    .ant-table-thead {
      th:nth-child(1),
      th:nth-child(2) {
        width: 140px;
      }
    }
  }
  .statement {
    strong {
      font-size: 26px;
      display: inline-block;
      color: #333;
      border-bottom: 2px solid var(--color-tint);
      margin-bottom: 26px;
    }
    > p {
      line-height: 36px;
      font-size: 20px;
      color: #666;
    }
  }
.ant-table-tbody tr td:nth-child(3) {
    font-size: 18px;
  }
  .oneTable {
    margin-bottom: 30px;
    .ant-table-tbody tr {
      height: 460px;
    }
  }
  .twoTable,
  .threeTable,
  .fourTable {
    margin-bottom: 30px;
    .ant-table-tbody tr {
      height: 402px;
    }
  }
  .fiveTable {
    .ant-table-tbody tr {
      height: 460px;
    }
  }
}
::v-deep .shadowSize {
  box-shadow: none !important;
}
::v-deep .assessmentRecord {
  .evaluating_title_box {
    h3 {
      font-size: 32px;
      padding-top: 0;
    }
    .evaluating_title_item {
      font-size: 20px;
    }
  }
  .evaluating_box_in {
    width: 1200px;
    .evaluation_item_title {
      font-size: 26px;
      margin-bottom: 33px;
    }
  }
  .score {
    font-size: 20px;
    width: 50px;
    padding-right: 10px;
    text-align: right;
    span {
      padding-bottom: 15px;
    }
  }
  .dimensionBox_in_item span {
    width: 70px !important;
    height: 18px !important;
  }
  .dimensionBox {
    .color_btn {
      width: 70px;
      font-size: 17px;
    }
    .color_btn6 > span {
      width: 70px;
    }
    .dimensionBox_in {
      width: 70px;
    }
  }
  .baseInfo {
    li:nth-child(2) span:nth-child(1):before {
      background-position: -134px 0;
    }
    li:nth-child(3) span:nth-child(1):before {
      background-position: -178px 0;
    }
    li:nth-child(4) span:nth-child(1):before {
      background-position: -286px 0;
    }
    li:nth-child(5) span:nth-child(1):before {
      background-position: -105px 0;
    }
    li:nth-child(6) span:nth-child(1):before {
      background-position: -76px 0;
    }
  }
  .shadowSize.mar_top_36 {
    margin-bottom: 0;
  }
  .evaluating_title_box h3 {
    display: none;
  }
}
.previewTitle {
  font-size: 56px;
  color: #333;
  font-weight: 500;
  padding: 530px 0 0 0;
  margin: 0;
  text-align: center;
}
::v-deep .backBtn {
  .backBtn {
    margin: 0;
  }
}
::v-deep .back-Btn.backBtn {
  position: absolute;
  top: 81px;
  right: 10px;
  z-index: 10;
  .ant-btn {
    background: none;
    border: 1px solid var(--color-tint);
    color: var(--color-tint);
  }
}

.previewItem {
  height: 1698px;
  .previewItemHead {
    height: 114px;
    text-align: center;
    font-size: #333;
    line-height: 114px;
    font-size: 36px;
    background: url('../../assets/images/preview/item_head.png') no-repeat;
  }
}
.caseInfo {
  padding: 19px 38px 11px;
  > div {
    margin-bottom: 18px;
    strong {
      font-size: 26px;
      border-bottom: 2px solid var(--color-tint);
      font-weight: normal;
      padding-bottom: 7px;
    }
  }
  > p {
    line-height: 40px;
    font-size: 20px;
    color: #666;
  }
}
::v-deep .mainChart {
  height: 780px;
  width: 1150px;
  padding-bottom: 30px;
}
::v-deep .ant-table-wrapper {
  margin-top: 14px;
  padding: 0 17px;
  .ant-table {
    font-size: 21px;
  }
}
.assessmentResult {
  ::v-deep .ant-table {
    .ant-table-tbody {
      tr {
        height: 260px;
      }
    }
    .ant-table-thead tr {
      th:nth-child(6) {
        text-align: center;
      }
      th:nth-child(7) {
        text-align: center;
      }
    }
    .ant-table-tbody {
      td {
        letter-spacing: 1px;
      }
      td:nth-child(6) {
        line-height: 42px;
      }

    }
  }
}
.assessmentResult2 {
  ::v-deep .ant-table {
    .ant-table-tbody {
      tr {
        height: 206px;
      }
    }
  }
}
::v-deep .ant-table-thead > tr > th {
  background: #ffbe27;
  font-size: 24px;
}
.previewPlan {
  ::v-deep .ant-table-tbody {
    tr {
      height: 1465px;
      font-size: 22px;
      td:nth-child(1),td:nth-child(2) {
            vertical-align: top;
             padding-top: 212px;
        }
    }
    p {
      margin: 0;
      line-height: 32px;
      font-size: 18px;
      padding: 0 10px;
      border-bottom: 1px solid #e8e8e8;
    }
    p:last-child {
      color: #740202;
      background: #fff6c9;
    }
    tr td:nth-child(3) {
      vertical-align: top;
      padding: 0;
    }
  }
  ::v-deep .ant-table-thead tr {
    th:nth-child(3) {
      text-align: center;
    }
  }
}
.proposalTitle{font-size: 22px; margin: 27px 0 ; color: #666;}
.proposalBody {
   padding: 0 76px 0 45px;
  .title {
    font-size: 26px;
    color: #333;
    margin-bottom: 20px;
    > span {
      padding: 0 10px;
      padding-bottom: 2px;
    }
  }
  .iconLine {
    background: url('../../assets/images/line.png') repeat-x left bottom;
  }
  .proposalItemInfo {
    p{font-size: 20px;line-height: 40px; margin:0}
  }
  .proposalItemInfo ul{
    li{
      display: flex; font-size: 22px; margin-bottom: 24px;
      >strong{font-weight: normal;}
      .timeItem{
        >span{padding-right: 40px; background: url('../../assets/images/icon_select.png') no-repeat 0 4px;padding-left:34px;}
      }
    }
  }
}
.proposal{position: relative;
.signItem{
    position: absolute; right:94px; bottom: 100px; display: flex;
    >strong{
      font-size: 26px; font-weight: normal; color: #333;
    }
    >span{
      width: 223px; border-bottom: 1px solid #707070;
    }
  }}
.colorName {
  color: var(--color-tint);
  font-style: normal;
}
</style>
